import { ThesisSubmissionIterationStatus } from '@prisma/client'

/**
 * This file contains the master data for all thesis statuses and their corresponding messages.
 * A status itself is not enough to determine what to show users, as the status might have "ended"
 * with an outcome and a new status was not created.
 */

export type THESIS_ITERATION_STATE_CODE =
  | 'CANDIDATE_SUBMITTING'
  | 'CANDIDATE_SUBMITTING_PA_CHANGES'
  | 'CANDIDATE_SUBMITTING_GS_CHANGES'
  | 'CANDIDATE_SUBMITTING_WITHDRAWN'
  | 'PRINCIPAL_ADVISOR_REVIEW'
  | 'GRADUATE_SCHOOL_REVIEW'
  | 'AWAITING_EXAMINERS_APPROVAL'
  | 'EXAMINERS_REVIEWING'
  | 'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION'
  | 'AWAITING_ORAL_EXAMINATION'
  | 'AWAITING_CHAIR_EXAMINATION_OUTCOME'
  | 'AWAITING_DEAN_OUTCOME'
  | 'DEAN_MARKED_AS_REVISE_AND_RESUBMIT'
  | 'CANDIDATE_SUBMITTING_FINAL_THESIS'
  | 'CANDIDATE_SUBMITTING_FINAL_GS_CHANGES'
  | 'CANDIDATE_SUBMITTING_FINAL_PA_CHANGES'
  | 'GRADUATE_SCHOOL_FINAL_REVIEW'
  | 'PRINCIPAL_ADVISOR_FINAL_REVIEW'
  | 'DEAN_FINAL_REVIEW'
  | 'AWAITING_PROVOST_CONFERRAL'
  | 'GRADUATE_SCHOOL_COMPLETES_CANDIDATURE'
  | 'THESIS_COMPLETE'
  | 'CANDIDATE_SUBMITTING_CHANGES'
  | 'CANDIDATE_SUBMITTING_CHANGES_PA_ADDITIONAL'
  | 'CANDIDATE_SUBMITTING_CHANGES_CHAIR_ADDITIONAL'
  | 'CHAIR_SUBMITTING_CHANGES_DEAN_ADDITIONAL'
  | 'PRINCIPAL_ADVISOR_REVIEWING_CHANGES'
  | 'CHAIR_REVIEWING_CHANGES'
  | 'DEAN_REVIEWING_CHANGES'
  | 'CANDIDATE_REVISING_AND_RESUBMITTING'
  | 'THESIS_SUBMISSION_FAILED'
  | 'DOWNGRADED_TO_MPHIL'

// Define the enum
export enum NotificationState {
  ACTION_REQUIRED = 2, // Orange
  CANDIDATE_SUBMITTING = 3, // Green
  NO_ACTION = 4, // Blue
}

type IterationStateMessage = {
  title: string
  message: string
  action: NotificationState.CANDIDATE_SUBMITTING | NotificationState.ACTION_REQUIRED | NotificationState.NO_ACTION
}

export type IterationStateDetails = {
  code: THESIS_ITERATION_STATE_CODE
  candidateMessage: IterationStateMessage // Used for emails and thesis dashboard
  principalAdvisorMessage: IterationStateMessage // Used for PA thesis view
  chairMessage: IterationStateMessage // Used for Chair thesis dashboard
  examinerMessage: IterationStateMessage // Used for Examiner thesis dashboard
  graduateSchoolMessage: IterationStateMessage // Used for GS thesis dashboard
  deanMessage: IterationStateMessage // Used for Dean on GS thesis dashboard
  provostMessage: IterationStateMessage // Used for Dean on GS thesis dashboard
  canSubmitIteration: boolean
  thesisState: 'INITIAL' | 'FINAL' | 'REQUEST_CHANGES'
}

const iterationStateDetails: IterationStateDetails[] = [
  // Initial thesis submission
  {
    code: 'CANDIDATE_SUBMITTING',
    candidateMessage: {
      title: 'Congratulations!',
      message:
        'The Graduate School has marked you eligible for thesis submission! Use this form to submit your thesis for examination.',
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'Candidate submitting thesis',
      message:
        'Congratulations, the Graduate School has marked your HDR student for thesis submission! Your student can use UQRDM to submit their thesis for examination.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate submitting thesis',
      message:
        'The candidate will submit their thesis here, which will be reviewed by the Graduate School and their Principal Advisor, before being sent to the examiners. Once both examiners have submitted their feedback, an oral examination with be scheduled and held. You will be notified directly when your actions are required in this process.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate submitting thesis',
      message:
        'The candidate will submit their thesis here, which will be reviewed by the Graduate School and their Principal Advisor, before being sent to the examiners. Once both examiners have submitted their feedback, an oral examination with be scheduled and held. You will be notified directly when your actions are required in this process.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Candidate submitting thesis',
      message: 'The student is currently submitting their thesis.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Candidate submitting thesis',
      message: 'The student is currently submitting their thesis.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Candidate submitting thesis',
      message: 'The student is currently submitting their thesis.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'INITIAL',
  },
  {
    code: 'CANDIDATE_SUBMITTING_PA_CHANGES',
    candidateMessage: {
      title: 'Your Principal Advisor has requested edits',
      message:
        'Please update your thesis submission as per the message below. After submitting your thesis, the thesis will go to the Graduate School for review and then to your Principal Advisor.',
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'You have requested edits',
      message: 'Once they submit their thesis again, you will be notified when your action is required.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Principal Advisor has requested edits to the thesis before it is suitable for examination. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Principal Advisor has requested edits to the thesis before it is suitable for examination. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Principal Advisor has requested edits',
      message:
        "The student has received a notification with the Principal Advisor's comments. Once they submit their thesis again, the Principal Advisor will receive an email notification. Comments provided by the Principal Advisor:",
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Candidate submitting thesis',
      message:
        'The student is currently submitting their thesis for examination. You will be required to review the thesis once submitted by the candidate.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Candidate submitting thesis',
      message:
        'The student is currently submitting their thesis for examination. You will be required to review the thesis once submitted by the candidate.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'INITIAL',
  },
  {
    code: 'CANDIDATE_SUBMITTING_GS_CHANGES',
    candidateMessage: {
      title: 'The Graduate School has requested edits',
      message:
        'Please update your thesis submission as per the message below. After submitting your thesis, the thesis will go to the Graduate School for review and then to your Principal Advisor.',
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'Graduate School has requested edits',
      message:
        "Your student has received a notification with the Graduate School's comments. Once they submit their thesis again, you will receive an email notification. Comments provided by the Graduate School:",
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Graduate School has requested edits to the thesis before it is suitable for examination. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Graduate School has requested edits to the thesis before it is suitable for examination. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Graduate School has requested edits',
      message:
        "The candidate has received a notification with the Graduate School's comments. Once they submit their thesis again, the Principal Advisor will receive an email notification. Graduate School's message:",
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Candidate submitting thesis',
      message:
        'The student is currently submitting their thesis for examination. You will be required to review the thesis once submitted by the candidate.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Candidate submitting thesis',
      message:
        'The student is currently submitting their thesis for examination. You will be required to review the thesis once submitted by the candidate.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'INITIAL',
  },
  {
    code: 'CANDIDATE_SUBMITTING_WITHDRAWN',
    candidateMessage: {
      title: 'Thesis was successfully withdrawn',
      message:
        'Your thesis was successfully withdrawn. You are now able to create a new thesis submission using UQRDM.',
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'Thesis withdrawn',
      message:
        'Your student has withdrawn their thesis submission. When they resubmit their thesis you will get a notification.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Thesis submission withdrawn',
      message: 'The candidate has withdrawn their thesis submission.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Thesis submission withdrawn',
      message: 'The candidate has withdrawn their thesis submission.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Thesis submission withdrawn',
      message: 'The candidate has withdrawn their thesis submission.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Thesis submission withdrawn',
      message: 'The candidate has withdrawn their thesis submission.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Thesis submission withdrawn',
      message: 'The candidate has withdrawn their thesis submission.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'INITIAL',
  },
  {
    code: 'GRADUATE_SCHOOL_REVIEW',
    candidateMessage: {
      title: 'The Graduate School is reviewing your thesis',
      message:
        'The Graduate School has been notified of your submission. They will review your files and either approve or request edits.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Graduate School review',
      message: "The Graduate School is reviewing your student's thesis submission.",
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Graduate School review',
      message:
        'The candidate will submit their thesis here, which will be reviewed by the Graduate School and their Principal Advisor, before being sent to the examiners. Once both examiners have submitted their feedback, an oral examination with be scheduled and held. You will be notified directly when your actions are required in this process.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Graduate School review',
      message:
        'The candidate will submit their thesis here, which will be reviewed by the Graduate School and their Principal Advisor, before being sent to the examiners. Once both examiners have submitted their feedback, an oral examination with be scheduled and held. You will be notified directly when your actions are required in this process.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Graduate School review',
      message: 'The submission can now be approved or edits can be requested.',
      action: NotificationState.ACTION_REQUIRED,
    },
    deanMessage: {
      title: 'Graduate School review',
      message: 'The submission can now be approved or edits can be requested.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Graduate School review',
      message: 'The submission can now be approved or edits can be requested.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'PRINCIPAL_ADVISOR_REVIEW',
    candidateMessage: {
      title: 'Your Principal Advisor is reviewing your thesis',
      message:
        'The Graduate School has approved your thesis. Your Principal Advisor will now review your thesis. They will review and approve the submission or ask you to make edits.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Thesis submission for your review',
      message:
        "Click below to approve your student's thesis submission or request any edits. By approving this thesis, you acknowledge that you have read the thesis and confirm that the thesis is of academic standard appropriate for external examination.",
      action: NotificationState.ACTION_REQUIRED,
    },
    chairMessage: {
      title: 'Thesis submission under review by the Principal Advisor',
      message:
        'The candidate will submit their thesis here, which will be reviewed by the Graduate School and their Principal Advisor, before being sent to the examiners. Once both examiners have submitted their feedback, an oral examination with be scheduled and held. You will be notified directly when your actions are required in this process.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Thesis submission under review by the Principal Advisor',
      message:
        'The candidate will submit their thesis here, which will be reviewed by the Graduate School and their Principal Advisor, before being sent to the examiners. Once both examiners have submitted their feedback, an oral examination with be scheduled and held. You will be notified directly when your actions are required in this process.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Thesis submission under review by the Principal Advisor',
      message:
        'The Principal Advisor is currently reviewing the thesis. Once the Principal Advisor has approved the thesis, the submission will progress to the Graduate School to initiate examination.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Thesis submission under review by the Principal Advisor',
      message:
        'The Principal Advisor is currently reviewing the thesis. Once the Principal Advisor has approved the thesis, the submission will progress to the Graduate School to initiate examination.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Thesis submission under review by the Principal Advisor',
      message:
        'The Principal Advisor is currently reviewing the thesis. Once the Principal Advisor has approved the thesis, the submission will progress to the Graduate School to initiate examination.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'AWAITING_EXAMINERS_APPROVAL',
    candidateMessage: {
      title: 'The Graduate School is initiating the thesis examination',
      message:
        'Your Principal Advisor has approved your thesis for examination. The Graduate School is reviewing and confirming all details of your thesis submission and examination committee. They will initiate the examination and examiners will be notified and provided with the thesis documents. You will be notified when the examiner review begins.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'The Graduate School is initiating the thesis examination',
      message:
        'The Graduate School is reviewing and confirming all details of the thesis submission and examination committee. They will initiate the examination and examiners will be notified and granted access to the thesis documents.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'The Graduate School is initiating the thesis examination',
      message:
        'The Graduate School is reviewing and confirming all details of the thesis submission and examination committee. They will initiate the examination and examiners will be notified and granted access to the thesis documents.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'The Graduate School is initiating the thesis examination',
      message:
        'The Graduate School is reviewing and confirming all details of the thesis submission and examination committee. They will initiate the examination and you will be notified and granted access to the thesis documents for your review.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Review the details and initiate the thesis examination',
      message:
        'Please confirm the details of the thesis submission and examination committee and then initiate the examination. The examiners will be notified and granted access to the thesis documents for review.',
      action: NotificationState.ACTION_REQUIRED,
    },
    deanMessage: {
      title: 'Review the details and initiate the thesis examination',
      message:
        'Please confirm the details of the thesis submission and examination committee and then initiate the examination. The examiners will be notified and granted access to the thesis documents for review.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Review the details and initiate the thesis examination',
      message:
        'Please confirm the details of the thesis submission and examination committee and then initiate the examination. The examiners will be notified and granted access to the thesis documents for review.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'EXAMINERS_REVIEWING',
    candidateMessage: {
      title: 'Examiners are reviewing your thesis',
      message:
        'The examiners have been notified that you have submitted your thesis. They will review the submission and provide feedback within UQRDM. Once feedback is provided, the Graduate School will review and confirm oral examination date.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: "Examiners are reviewing your candidate's thesis",
      message:
        'The examiners have been notified that the candidate has submitted their thesis. They will review the submission and provide feedback within UQRDM. Once feedback is provided, the Graduate School will review and confirm oral examination date.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Examiners reviewing thesis',
      message:
        'The candidate will submit their thesis here, which will be reviewed by the Graduate School and their Principal Advisor, before being sent to the examiners. Once both examiners have submitted their feedback, an oral examination with be scheduled and held. You will be notified directly when your actions are required in this process.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Thesis submission for your review',
      message:
        "Please review the thesis from the 'Thesis documents' pane and complete the examination review from the 'Examination of thesis documents' pane, below.",
      action: NotificationState.ACTION_REQUIRED,
    },
    graduateSchoolMessage: {
      title: 'Examiners reviewing thesis',
      message:
        'The examiners have been given access to the submitted thesis. They will review the submission and provide feedback within UQRDM. Once feedback is provided, the Graduate School will review and confirm oral examination date.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Examiners reviewing thesis',
      message:
        'The examiners have been given access to the submitted thesis. They will review the submission and provide feedback within UQRDM. Once feedback is provided, the Graduate School will review and confirm oral examination date.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Examiners reviewing thesis',
      message:
        'The examiners have been given access to the submitted thesis. They will review the submission and provide feedback within UQRDM. Once feedback is provided, the Graduate School will review and confirm oral examination date.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'GRADUATE_SCHOOL_SCHEDULING_ORAL_EXAMINATION',
    candidateMessage: {
      title: 'The Graduate School is scheduling the oral examination',
      message:
        'Examiner feedback has been submitted and the Graduate School is contacting all participants to confirm a date and time for the oral examination. Please reply promptly to any emails to facilitate scheduling.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'The Graduate School is scheduling the oral examination',
      message:
        'Examiner feedback has been submitted and the Graduate School is contacting all participants to confirm a date and time for the oral examination. Please reply promptly to any emails to facilitate scheduling.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'The Graduate School is scheduling the oral examination',
      message:
        'Examiner feedback has been submitted and the Graduate School is contacting all participants to confirm a date and time for the oral examination. Please reply promptly to any emails to facilitate scheduling.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'The Graduate School is scheduling the oral examination',
      message:
        'Examiner feedback has been submitted and the Graduate School is contacting all participants to confirm a date and time for the oral examination. Please reply promptly to any emails to facilitate scheduling.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'The Graduate School is scheduling the oral examination',
      message:
        'Examiner feedback has been submitted. Please review the feedback before scheduling the date of the oral examination. Examiner feedback reports can be released below if the candidate or Principal Advisor need to review the feedback before the oral examination date is scheduled.',
      action: NotificationState.ACTION_REQUIRED,
    },
    deanMessage: {
      title: 'The Graduate School is scheduling the oral examination',
      message:
        'Examiner feedback has been submitted. Please review the feedback before scheduling the date of the oral examination. Examiner feedback reports can be released below if the candidate or Principal Advisor need to review the feedback before the oral examination date is scheduled.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'The Graduate School is scheduling the oral examination',
      message:
        'Examiner feedback has been submitted. Please review the feedback before scheduling the date of the oral examination. Examiner feedback reports can be released below if the candidate or Principal Advisor need to review the feedback before the oral examination date is scheduled.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'AWAITING_ORAL_EXAMINATION',
    candidateMessage: {
      title: 'The oral examination date has been set',
      message: 'Please review the examiner feedback in preparation for your oral examination.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'The oral examination date has been set',
      message: 'Please review the examiner feedback in preparation for the oral examination.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'The oral examination date has been set',
      message:
        'Please review the examiner feedback in preparation for the oral examination. Your summary report is available to view and familiarize yourself with the feedback required.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'The oral examination date has been set',
      message: 'Please review the examiner feedback in preparation for the oral examination.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'The oral examination date has been set',
      message: 'If the date of the examination changes, please update it in UQRDM.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'The oral examination date has been set',
      message: 'If the date of the examination changes, please update it in UQRDM.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'The oral examination date has been set',
      message: 'If the date of the examination changes, please update it in UQRDM.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'AWAITING_CHAIR_EXAMINATION_OUTCOME',
    candidateMessage: {
      title: 'Chair of Examiners is completing summary report',
      message:
        'The Chair is reviewing the examiner feedback and preparing their summary report. Once completed, the Dean of the Graduate School will review the report and determine the outcome.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Chair of Examiners is completing summary report',
      message:
        'The Chair is reviewing the examiner feedback and preparing their summary report. Once completed, the Dean of the Graduate School will review the report and determine the outcome.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Review and submit your examination summary report',
      message:
        'Please review the examiner feedback and prepare your summary report. Once completed, the Dean of the Graduate School will review the report and determine the outcome.',
      action: NotificationState.ACTION_REQUIRED,
    },
    examinerMessage: {
      title: 'Chair of Examiners is completing summary report',
      message:
        'The Chair is reviewing the examiner feedback and preparing their summary report. Once completed, the Dean of the Graduate School will review the report and determine the outcome.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Chair of Examiners is completing summary report',
      message:
        'The Chair is reviewing the examiner feedback and preparing their summary report. Once completed, the Dean of the Graduate School will review the report and determine the outcome.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Chair of Examiners is completing summary report',
      message:
        'The Chair is reviewing the examiner feedback and preparing their summary report. Once completed, the Dean of the Graduate School will review the report and determine the outcome.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Chair of Examiners is completing summary report',
      message:
        'The Chair is reviewing the examiner feedback and preparing their summary report. Once completed, the Dean of the Graduate School will review the report and determine the outcome.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'AWAITING_DEAN_OUTCOME',
    candidateMessage: {
      title: 'The Dean of the Graduate School is endorsing the examination outcome',
      message:
        'The Dean of the Graduate School is reviewing the examination and summary report. They will determine an outcome and you will be notified.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'The Dean of the Graduate School is endorsing the examination outcome',
      message:
        'The Dean of the Graduate School is reviewing the examination and summary report. They will determine an outcome and the candidate will be notified.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'The Dean of the Graduate School is endorsing the examination outcome',
      message:
        'The Dean of the Graduate School is reviewing the examination and summary report. They will determine an outcome and the candidate will be notified.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'The Dean of the Graduate School is endorsing the examination outcome',
      message:
        'The Dean of the Graduate School is reviewing the examination and summary report. They will determine an outcome and the candidate will be notified.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'The Dean of the Graduate School is endorsing the examination outcome',
      message:
        'The Dean of the Graduate School is reviewing the examination and summary report. They will determine an outcome and the candidate will be notified.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Review the examination details and endorse an outcome',
      message: 'Please review the examination and Chair summary report and confirm an outcome for the thesis.',
      action: NotificationState.ACTION_REQUIRED,
    },
    provostMessage: {
      title: 'Review the examination details and endorse an outcome',
      message: 'Please review the examination and Chair summary report and confirm an outcome for the thesis.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  // Final thesis submission
  {
    code: 'CANDIDATE_SUBMITTING_FINAL_THESIS',
    candidateMessage: {
      title: 'Your thesis was approved for final submission',
      message:
        "Congratulations on successfully completing your examination! At this stage, you need to submit your final thesis, which will be reviewed and then published in UQ eSpace, the University's institutional repository.",
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: "Your candidate's thesis was approved for final submission",
      message:
        'The candidate has been approved for thesis finalisation. After they submit the final thesis you will be notified when your action is required for review.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate is finalising and submitting their thesis',
      message:
        'The candidate has been approved for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate is finalising and submitting their thesis',
      message:
        'The candidate has been approved for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Thesis was approved for final submission',
      message:
        'The candidate has been approved  for thesis finalisation. After they submit the final thesis, your review will be required.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Thesis was approved for final submission',
      message:
        'The candidate has been approved  for thesis finalisation. After they submit the final thesis you will be notified when your action is required for review.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Thesis was approved for final submission',
      message:
        'The candidate has been approved  for thesis finalisation. After they submit the final thesis you will be notified when your action is required for review.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'FINAL',
  },
  {
    code: 'CANDIDATE_SUBMITTING_FINAL_PA_CHANGES',
    candidateMessage: {
      title: 'Your Principal Advisor has requested edits',
      message:
        "Please update your thesis submission as per the message below. After submitting your thesis, the thesis will go to the Graduate School for review and then to your Principal Advisor. Your Principal Advisor's message:",
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'You have requested edits',
      message:
        'Your student has received a notification with your comments. Once they submit their thesis again, you will receive an email notification. Your message:',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Principal Advisor has requested edits to the final thesis before it is suitable for publication. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Principal Advisor has requested edits to the final thesis before it is suitable for publication. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Principal Advisor has requested edits',
      message:
        "The student has received a notification with the Principal Advisor's comments. Once they submit their thesis again, the Principal Advisor will receive an email notification. Comments provided by the Principal Advisor:",
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Principal Advisor has requested edits',
      message:
        "The student has received a notification with the Principal Advisor's comments. Once they submit their thesis again, the Principal Advisor will receive an email notification. Comments provided by the Principal Advisor:",
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Principal Advisor has requested edits',
      message:
        "The student has received a notification with the Principal Advisor's comments. Once they submit their thesis again, the Principal Advisor will receive an email notification. Comments provided by the Principal Advisor:",
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'FINAL',
  },
  {
    code: 'CANDIDATE_SUBMITTING_FINAL_GS_CHANGES',
    candidateMessage: {
      title: 'The Graduate School has requested edits',
      message:
        "Please update your thesis submission as per the message below. After submitting your thesis, the thesis will go to the Graduate School for review and then to your Principal Advisor. The Graduate School's message:",
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'Graduate School has requested edits',
      message:
        "Your student has received a notification with the Graduate School's comments. Once they submit their thesis again, you will receive an email notification. The Graduate School's message:",
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Graduate School has requested edits to the thesis before it is suitable for publication. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Graduate School has requested edits to the thesis before it is suitable for publication. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Graduate School has requested edits',
      message:
        "The candidate has received a notification with the Graduate School's comments. Once they submit their thesis again, the Principal Advisor will receive an email notification. Comments provided by the Graduate School:",
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Graduate School has requested edits',
      message:
        "The candidate has received a notification with the Graduate School's comments. Once they submit their thesis again, the Principal Advisor will receive an email notification. Comments provided by the Graduate School:",
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Graduate School has requested edits',
      message:
        "The candidate has received a notification with the Graduate School's comments. Once they submit their thesis again, the Principal Advisor will receive an email notification. Comments provided by the Graduate School:",
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'FINAL',
  },
  {
    code: 'GRADUATE_SCHOOL_FINAL_REVIEW',
    candidateMessage: {
      title: 'The Graduate School is reviewing your thesis',
      message:
        'The Graduate School is reviewing the final thesis and can request edits or proceed to review by the Principal Advisor.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Graduate School reviewing',
      message:
        'The Graduate School is reviewing the final thesis and can request edits or proceed to review by the Principal Advisor.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Graduate School reviewing the final thesis',
      message:
        'The candidate has been approved  for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Graduate School reviewing the final thesis',
      message:
        'The candidate has been approved  for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Graduate School review of final thesis submission',
      message:
        'Please review the final thesis and approve for Principal Advisor review or request edits. Unnecessary supplemental documents can be removed.',
      action: NotificationState.ACTION_REQUIRED,
    },
    deanMessage: {
      title: 'Graduate School review of final thesis submission',
      message:
        'The Graduate School is reviewing the final thesis and can request edits or proceed to review by the Principal Advisor.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Graduate School review of final thesis submission',
      message:
        'The Graduate School is reviewing the final thesis and can request edits or proceed to review by the Principal Advisor.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'FINAL',
  },
  {
    code: 'PRINCIPAL_ADVISOR_FINAL_REVIEW',
    candidateMessage: {
      title: 'Your Principal Advisor is reviewing your final thesis submission',
      message:
        'The Principal Advisor is reviewing the final thesis and can request edits or proceed to review by the Dean of the Graduate School.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Thesis submission for your review',
      message:
        'Please review the final thesis noting any intellectual property designation or embargo requirements and approve for Dean review or request edits from the candidate.',
      action: NotificationState.ACTION_REQUIRED,
    },
    chairMessage: {
      title: 'Principal Advisor reviewing the final thesis',
      message:
        'The candidate has been approved for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Principal Advisor reviewing the final thesis',
      message:
        'The candidate has been approved for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Principal Advisor is reviewing final thesis',
      message:
        'The Principal Advisor is reviewing the final thesis and can request edits or proceed to review by the Dean of the Graduate School.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Principal Advisor is reviewing final thesis',
      message:
        'The Principal Advisor is reviewing the final thesis and can request edits or proceed to review by the Dean of the Graduate School.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Principal Advisor is reviewing final thesis',
      message:
        'The Principal Advisor is reviewing the final thesis and can request edits or proceed to review by the Dean of the Graduate School.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'FINAL',
  },
  {
    code: 'DEAN_FINAL_REVIEW',
    candidateMessage: {
      title: 'The Dean is reviewing the final thesis',
      message: 'The Dean is reviewing the final thesis and will approve for review and conferral by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'The Dean is reviewing the final thesis',
      message: 'The Dean is reviewing the final thesis and will approve for review and conferral by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'The Dean is reviewing the final thesis',
      message:
        'The candidate has been approved for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'The Dean is reviewing the final thesis',
      message:
        'The candidate has been approved for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'The Dean is reviewing the final thesis',
      message: 'The Dean is reviewing the final thesis and will approve for review and conferral by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'The Dean is reviewing the final thesis',
      message: 'Please review the final thesis and approve for the Provost to review and confer.',
      action: NotificationState.ACTION_REQUIRED,
    },
    provostMessage: {
      title: 'The Dean is reviewing the final thesis and will approve for review and conferral by the Provost.',
      message: 'The Dean is reviewing the final thesis and will approve for review and conferral by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'FINAL',
  },
  {
    code: 'AWAITING_PROVOST_CONFERRAL',
    candidateMessage: {
      title: 'Awaiting conferral',
      message: 'The Provost is reviewing the final thesis and will confer your degree.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Awaiting conferral',
      message: "The Provost is reviewing the final thesis and will confer the candidate's degree.",
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'The Provost is reviewing the final thesis',
      message:
        'The candidate has been approved for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'The Provost is reviewing the final thesis',
      message:
        'The candidate has been approved for thesis finalisation. The final thesis will be reviewed by the Dean and conferred by the Provost.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Awaiting conferral',
      message: "The Provost is reviewing the final thesis and will confer the candidate's degree.",
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Awaiting conferral',
      message: "The Provost is reviewing the final thesis and will confer the candidate's degree.",
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Awaiting conferral',
      message:
        'Please review the details of the thesis and examination before conferring the Higher Degree by Research.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'FINAL',
  },
  {
    code: 'GRADUATE_SCHOOL_COMPLETES_CANDIDATURE',
    candidateMessage: {
      title: 'Your thesis and HDR has been conferred by the Provost',
      message:
        'Congratulations, the Provost has reviewed the final thesis and conferred your Higher Degree by Research. The Graduate School is confirming details of completion and publication to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: "The candidate's thesis and HDR has been conferred by the Provost",
      message:
        "The Provost has reviewed the final thesis and conferred the candidate's Higher Degree by Research. The Graduate School is confirming details of completion and publication to UQ eSpace.",
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: "The candidate's thesis and HDR has been conferred by the Provost",
      message:
        "The Provost has reviewed the final thesis and conferred the candidate's Higher Degree by Research. The Graduate School is confirming details of completion and publication to UQ eSpace.",
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: "The candidate's thesis and HDR has been conferred by the Provost",
      message:
        "The Provost has reviewed the final thesis and conferred the candidate's Higher Degree by Research. The Graduate School is confirming details of completion and publication to UQ eSpace.",
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: "The candidate's thesis and HDR has been conferred by the Provost",
      message:
        'The Graduate School is confirming details of completion to notify the Graduations Team, who will notify Student Academic Services to confirm the degree, Human Resources to complete the enrollment and appointment, and UQ eSpace to publish the thesis.',
      action: NotificationState.ACTION_REQUIRED,
    },
    deanMessage: {
      title: "The candidate's thesis and HDR has been conferred by the Provost",
      message:
        "The Provost has reviewed the final thesis and conferred the candidate's Higher Degree by Research. The Graduate School is confirming details of completion and publication to UQ eSpace.",
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: "The candidate's thesis and HDR has been conferred by the Provost",
      message:
        'The Provost has reviewed the final thesis and conferred the candidate’s Higher Degree by Research. The Graduate School is confirming details of completion and publication to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'FINAL',
  },
  {
    code: 'THESIS_COMPLETE',
    candidateMessage: {
      title: 'Thesis complete',
      message: 'Your candidature is now complete. Your thesis will be published to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Thesis complete',
      message: 'The candidature is now complete. The thesis will be published to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: "The candidate's thesis and HDR has been conferred by the Provost",
      message: 'The candidature is now complete. The thesis will be published to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: "The candidate's thesis and HDR has been conferred by the Provost",
      message: 'The candidature is now complete. The thesis will be published to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Thesis complete',
      message: 'The candidature is now complete. The thesis will be published to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Thesis complete',
      message: 'The candidature is now complete. The thesis will be published to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Thesis complete',
      message: 'The candidature is now complete. The thesis will be published to UQ eSpace.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'FINAL',
  },

  // Request corrections
  {
    code: 'CANDIDATE_SUBMITTING_CHANGES',
    candidateMessage: {
      title: 'Thesis corrections requested',
      message:
        'The Dean of the Graduate School has reviewed your thesis, examination, and committee Chair summary report. Your thesis has passed with corrections required. Please review the feedback of your examiners and the summary report, then submit your corrected thesis with the accompanying corrections file for review.',
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'Thesis corrections requested',
      message:
        'The Dean of the Graduate School has reviewed the thesis, examination, and committee Chair summary report. The thesis has passed with corrections required. The candidate will submit a corrected thesis with the accompanying corrections file for review.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate is submitting the requested corrections.',
      message:
        'The Dean of the Graduate School has reviewed the thesis, examination, and committee Chair summary report. The thesis has passed with corrections required. The candidate will submit a corrected thesis with the accompanying corrections file for review.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate is submitting the requested corrections.',
      message:
        'The Dean of the Graduate School has reviewed the thesis, examination, and committee Chair summary report. The thesis has passed with corrections required. The candidate will submit a corrected thesis to be reviewed by the committee and Graduate School. You will not be required to review the corrections.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Thesis corrections requested',
      message:
        'The Dean of the Graduate School has reviewed the thesis, examination, and committee Chair summary report. The thesis has passed with corrections required. The candidate will submit a corrected thesis with the accompanying corrections file for review.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Thesis corrections requested',
      message:
        'The Dean of the Graduate School has reviewed the thesis, examination, and committee Chair summary report. The thesis has passed with corrections required. The candidate will submit a corrected thesis with the accompanying corrections file for review.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Thesis corrections requested',
      message:
        'The Dean of the Graduate School has reviewed the thesis, examination, and committee Chair summary report. The thesis has passed with corrections required. The candidate will submit a corrected thesis with the accompanying corrections file for review.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'REQUEST_CHANGES',
  },
  {
    code: 'CANDIDATE_SUBMITTING_CHANGES_PA_ADDITIONAL',
    candidateMessage: {
      title: 'Principal Advisor has requested additional changes',
      message:
        'The Principal Advisor has requested additional changes to your thesis submission. Please read the message below and resubmit your thesis.',
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'Principal Advisor has requested additional changes',
      message:
        "The Principal Advisor has requested additional changes to the candidate's submission as per the message below.",
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Principal Advisor has requested edits to the corrected thesis before it is suitable for review. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate submitting edits',
      message:
        'The Principal Advisor has requested edits to the corrected thesis before it is suitable for review. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Principal Advisor has requested additional changes',
      message:
        "The Principal Advisor has requested additional changes to the candidate's submission as per the message below.",
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Principal Advisor has requested additional changes',
      message:
        "The Principal Advisor has requested additional changes to the candidate's submission as per the message below.",
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Principal Advisor has requested additional changes',
      message:
        "The Principal Advisor has requested additional changes to the candidate's submission as per the message below.",
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'REQUEST_CHANGES',
  },
  {
    code: 'CANDIDATE_SUBMITTING_CHANGES_CHAIR_ADDITIONAL',
    candidateMessage: {
      title: 'The Chair has requested additional changes',
      message:
        'The Chair has requested additional changes to your thesis submission. Please read the message below and resubmit your thesis.',
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'The Chair has requested additional changes',
      message: "The Chair has requested additional changes to the candidate's submission as per the message below.",
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate submitting additional corrections',
      message:
        'You have requested additional corrections to the thesis before it is suitable for Dean review. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate submitting additional corrections',
      message:
        'The Chair has requested additional corrections to the thesis before it is suitable for Dean review. The candidate is making those changes now.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'The Chair has requested additional changes',
      message: "The Chair has requested additional changes to the candidate's submission as per the message below.",
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'The Chair has requested additional changes',
      message: "The Chair has requested additional changes to the candidate's submission as per the message below.",
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'The Chair has requested additional changes',
      message: "The Chair has requested additional changes to the candidate's submission as per the message below.",
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'REQUEST_CHANGES',
  },
  {
    code: 'CHAIR_SUBMITTING_CHANGES_DEAN_ADDITIONAL',
    candidateMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Please review comments from the Dean',
      message:
        'You can request further corrections from the candidate or discuss with the Dean and approve the existing submission.',
      action: NotificationState.ACTION_REQUIRED,
    },
    examinerMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review. ',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Please review comments from the Dean',
      message:
        'You can request further corrections from the candidate or discuss with the Dean and approve the existing submission.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Chair reviews corrections',
      message:
        'You have requested additional corrections to the thesis before it is suitable for finalisation. The chair is reviewing your comments.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review. ',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'REQUEST_CHANGES',
  },
  {
    code: 'PRINCIPAL_ADVISOR_REVIEWING_CHANGES',
    candidateMessage: {
      title: 'Principal Advisor reviews corrections',
      message:
        'Your Principal Advisor is now reviewing your thesis corrections. They can request further corrections or approve for the Chair to review.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Review the corrected thesis and approve or request further edits',
      message:
        'Please review the corrected thesis and accompanying corrections file. You can request further corrections or approve for Chair review.',
      action: NotificationState.ACTION_REQUIRED,
    },
    chairMessage: {
      title: 'Principal Advisor reviews corrections',
      message:
        'The Principal Advisor is now reviewing the thesis corrections. They can request further corrections or approve for the Chair to review.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Principal Advisor reviews corrections',
      message:
        'The Principal Advisor is now reviewing the thesis corrections. They can request further corrections or approve for the Chair to review.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Principal Advisor reviews corrections',
      message:
        'The Principal Advisor is now reviewing the thesis corrections. They can request further corrections or approve for the Chair to review.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Principal Advisor reviews corrections',
      message:
        'The Principal Advisor is now reviewing the thesis corrections. They can request further corrections or approve for the Chair to review.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Principal Advisor reviews corrections',
      message:
        'The Principal Advisor is now reviewing the thesis corrections. They can request further corrections or approve for the Chair to review.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'REQUEST_CHANGES',
  },
  {
    code: 'CHAIR_REVIEWING_CHANGES',
    candidateMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Review the corrected thesis and approve or request further corrections',
      message:
        'Please review the corrected thesis and accompanying corrections file. You can request further corrections or approve for Dean review.',
      action: NotificationState.ACTION_REQUIRED,
    },
    examinerMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review. ',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review. ',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review. ',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Chair reviews corrections',
      message:
        'The Chair is now reviewing the thesis corrections. They can request further corrections or approve for the Dean to review. ',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'REQUEST_CHANGES',
  },
  {
    code: 'DEAN_REVIEWING_CHANGES',
    candidateMessage: {
      title: 'The Dean is reviewing the corrected thesis',
      message:
        'The Dean is now reviewing the thesis corrections. They can request further corrections or approve for thesis finalisation.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'The Dean is reviewing the corrected thesis',
      message:
        'The Dean is now reviewing the thesis corrections. They can request further corrections or approve for thesis finalisation.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'The Dean is reviewing the corrected thesis',
      message:
        'The Dean is now reviewing the thesis corrections. They can request further corrections or approve for thesis finalisation.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'The Dean is reviewing the corrected thesis',
      message:
        'The Dean is now reviewing the thesis corrections. They can request further corrections or approve for thesis finalisation.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'The Dean is reviewing the corrected thesis',
      message:
        'The Dean is now reviewing the thesis corrections. They can request further corrections or approve for thesis finalisation.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Review the corrected thesis and approve or request further corrections',
      message:
        'Please review the corrected thesis and accompanying corrections file. You can request further corrections or approve for thesis finalisation.',
      action: NotificationState.ACTION_REQUIRED,
    },
    provostMessage: {
      title: 'Review the corrected thesis and approve or request further corrections',
      message:
        'Please review the corrected thesis and accompanying corrections file. You can request further corrections or approve for thesis finalisation.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'REQUEST_CHANGES',
  },
  {
    code: 'CANDIDATE_REVISING_AND_RESUBMITTING',
    candidateMessage: {
      title: 'The Dean has requested a revise and resubmit',
      message:
        'After review and feedback from the examiners and Chair, the Dean has required revisions of your thesis. Please view your previous submission to review the examination feedback and summary report while revising your thesis. You are intended to provide your resubmission for examination within 12 months.',
      action: NotificationState.CANDIDATE_SUBMITTING,
    },
    principalAdvisorMessage: {
      title: 'Candidate is currently revising and resubmitting',
      message:
        'After review and feedback from the examiners and Chair, the Dean has required revisions of the thesis. The candidate will provide their resubmission for examination within 12 months. You will be notified when your action on the thesis is required.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate is revising their thesis',
      message:
        'After review and feedback from the examiners and Chair, the Dean has required revisions of the thesis. The candidate will provide their resubmission for examination within 12 months. You will be notified when your action on the thesis is required.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate is revising their thesis',
      message:
        'After review and feedback from the examiners and Chair, the Dean has required revisions of the thesis. The candidate will provide their resubmission for examination within 12 months. You will be notified when your action on the thesis is required.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Candidate is currently revising and resubmitting',
      message:
        'After review and feedback from the examiners and Chair, the Dean has required revisions of the thesis. The candidate will provide their resubmission for examination within 12 months. You will be notified when your action on the thesis is required.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Candidate is currently revising and resubmitting',
      message:
        'After review and feedback from the examiners and Chair, the Dean has required revisions of the thesis. The candidate will provide their resubmission for examination within 12 months. You will be notified when your action on the thesis is required.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Candidate is currently revising and resubmitting',
      message:
        'After review and feedback from the examiners and Chair, the Dean has required revisions of the thesis. The candidate will provide their resubmission for examination within 12 months. You will be notified when your action on the thesis is required.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'INITIAL',
  },
  {
    code: 'DEAN_MARKED_AS_REVISE_AND_RESUBMIT',
    candidateMessage: {
      title: 'The Dean has requested a revise and resubmit',
      message: 'The Dean has requested a revise and resubmit with the below message.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'The Dean has requested a revise and resubmit',
      message:
        'The Dean has requested a revise and resubmit. The candidate will need to resubmit their thesis as per the message below.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Examination outcome of Revise and Resubmit',
      message:
        'The thesis required revisions. The new submission of the revised thesis will be visible in the new submission.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Examination outcome of Revise and Resubmit',
      message:
        'The thesis required revisions. The new submission of the revised thesis will be visible in the new submission.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'The Dean has requested a revise and resubmit.',
      message:
        'The Dean has requested a revise and resubmit. The candidate will need to resubmit their thesis as per the message below.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'The Dean has requested a revise and resubmit.',
      message:
        'The Dean has requested a revise and resubmit. The candidate will need to resubmit their thesis as per the message below.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'The Dean has requested a revise and resubmit.',
      message:
        'The Dean has requested a revise and resubmit. The candidate will need to resubmit their thesis as per the message below.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'THESIS_SUBMISSION_FAILED',
    candidateMessage: {
      title: 'Your thesis submission was unsuccessful',
      message: 'The Graduate School has marked your thesis submission as failed with the following message.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: 'Thesis submission was unsuccessful',
      message: 'The Graduate School has marked the thesis submission as failed with the following message.',
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'This thesis was unsuccessful.',
      message: '',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'This thesis was unsuccessful.',
      message: '',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: 'Thesis submission was unsuccessful',
      message: 'The Graduate School has marked the thesis submission as failed with the following message.',
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: 'Thesis submission was unsuccessful',
      message: 'The Graduate School has marked the thesis submission as failed with the following message.',
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: 'Thesis submission was unsuccessful',
      message: 'The Graduate School has marked the thesis submission as failed with the following message.',
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: false,
    thesisState: 'INITIAL',
  },
  {
    code: 'DOWNGRADED_TO_MPHIL',
    candidateMessage: {
      title: 'Your submission is ready for final submission',
      message: 'Your thesis submission was marked as MPhil and is now ready for final submission.',
      action: NotificationState.NO_ACTION,
    },
    principalAdvisorMessage: {
      title: "The candidate's submission is ready for final submission",
      message: "The candidate's thesis submission was marked as MPhil and is now ready for final submission.",
      action: NotificationState.NO_ACTION,
    },
    chairMessage: {
      title: 'Candidate submitting their thesis for review as a MPhil',
      message:
        'Your outcome of examination recommended submission as a Master’s of Philosophy. The candidate is submitting their final submission.',
      action: NotificationState.NO_ACTION,
    },
    examinerMessage: {
      title: 'Candidate submitting their thesis for review as a MPhil',
      message:
        'Your outcome of examination recommended submission as a Master’s of Philosophy. The candidate is submitting their final submission.',
      action: NotificationState.NO_ACTION,
    },
    graduateSchoolMessage: {
      title: "The candidate's submission is ready for final submission",
      message: "The candidate's thesis submission was marked as MPhil and is now ready for final submission.",
      action: NotificationState.NO_ACTION,
    },
    deanMessage: {
      title: "The candidate's submission is ready for final submission",
      message: "The candidate's thesis submission was marked as MPhil and is now ready for final submission.",
      action: NotificationState.NO_ACTION,
    },
    provostMessage: {
      title: "The candidate's submission is ready for final submission",
      message: "The candidate's thesis submission was marked as MPhil and is now ready for final submission.",
      action: NotificationState.NO_ACTION,
    },
    canSubmitIteration: true,
    thesisState: 'FINAL',
  },
]

export const customIterationStateMessages = {
  noOralExaminersReviewingCandidateMessage:
    'The examiners have been given access to the submitted thesis. They will review the submission and provide feedback within UQRDM. Once feedback is provided, the Chair will review it and recommend an outcome to the Dean.',
  noOralExaminersReviewingGraduateSchoolMessage:
    'The examiners have been given access to the submitted thesis. They will review the submission and provide feedback within UQRDM. Once feedback is provided, the Chair will review it and recommend an outcome to the Dean.',
  noOralExaminersReviewingChairMessage:
    'The examiners are currently reviewing the thesis. Once they provide their feedback, you will be asked to review it and submit your report.',
  noOralExaminersReviewingAdvisorMessage:
    'Once feedback is provided, the Chair and the Dean of the Graduate School will review it.',
}

export const getIterationStateByCode = (code: THESIS_ITERATION_STATE_CODE): IterationStateDetails => {
  return iterationStateDetails.find((s) => s.code === code)
}

type RequiredStatusFields = {
  status: ThesisSubmissionIterationStatus['status']
  outcome?: ThesisSubmissionIterationStatus['outcome']
}

export const getIterationStateCode = (status?: RequiredStatusFields): THESIS_ITERATION_STATE_CODE => {
  if (!status) return 'CANDIDATE_SUBMITTING'
  if (!status.outcome) return status.status as THESIS_ITERATION_STATE_CODE
  if (status.outcome === 'ADVANCED') {
    if (status.status === 'GRADUATE_SCHOOL_COMPLETES_CANDIDATURE') return 'THESIS_COMPLETE'
  }
  if (status.outcome === 'REVISE_AND_RESUBMIT') return 'DEAN_MARKED_AS_REVISE_AND_RESUBMIT'
  if (status.outcome === 'DOWNGRADE_TO_MPHIL') return 'DOWNGRADED_TO_MPHIL'
  if (status.outcome === 'FAILED') return 'THESIS_SUBMISSION_FAILED'

  return 'CANDIDATE_SUBMITTING'
}

export const getIterationState = (status?: RequiredStatusFields): IterationStateDetails => {
  return getIterationStateByCode(getIterationStateCode(status))
}
